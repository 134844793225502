import {chartDescriptionActionTypes} from './chartDescriptions.types';

const INITIAL_STATE = {
    home: null,
    supply: null,
    demand: null
}

const chartDescriptionsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case chartDescriptionActionTypes.SET_HOME_CHART_DESCRIPTIONS:
            return {
                ...state,
                home: action.payload
            };
        case chartDescriptionActionTypes.SET_SUPPLY_CHART_DESCRIPTIONS:
            return {
                ...state,
                supply: action.payload
            };
        case chartDescriptionActionTypes.SET_DEMAND_CHART_DESCRIPTIONS:
            return {
                ...state,
                demand: action.payload
            };
        default:
            return state;
    }
}

export default chartDescriptionsReducer;