import { Component } from 'react';
import aws4 from 'aws4';
import URL from 'url';
import appConfig from '../appConfig/appConfig';
import AuthCredentials from '../services/AuthCredentialService';
import Auth from '../modules/amplify/Auth'

const errorResponse = {
    status: 500,
    message: 'Failed to fetch the data'
};

class HttpService extends Component {

    get = async (url, headers) => {
        try {
            let res = await fetch(url, {
                method: 'GET',
                headers: headers,
            });
            return res.json();
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    getText = async (url, headers) => {
        try {
            let res = await fetch(url, {
                method: 'GET',
                headers: headers,
            });
            return res.text();
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    post = async (url, data, headers = {}) => {
        try {
            let req = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: headers
            };
            let res = await fetch(url, req);
            return res.json();
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    //for POST and PUT
    postSignedRequest = async (url, data, method = 'POST') => {
        try {
            let req = {
                url: url,
                method: method,
                body: data
            };

            let signedRequest = await this.buildSignedRequest(req);
            let res = await fetch(signedRequest.url, {
                method: method,
                body: signedRequest.body,
                headers: signedRequest.headers
            });
            return res.json();
        }
        catch (err) {
            console.log(err);
            throw err;
        }
    }

    getSignedReq = async (url) => {
        try {
            let req = {
                url: url,
                method: 'GET'
            };
            let signedRequest = await this.buildSignedRequest(req);
            let res = await fetch(signedRequest.url, {
                method: 'GET',
                mode: 'cors',
                //cache: 'no-cache',
                headers: signedRequest.headers,
                referrer: 'client'
            });
            let ContentType = res.headers.get('Content-Type');
            let status = res.status;
            if(ContentType === "application/json" && status !== 500){
                return res.json();
            }
            else if(status === 500){
               return errorResponse;
            }else{
                return res.text();
            }
        } catch (err) {
            console.log(err);
            throw err;
        }
    }


    buildSignedRequest = async (req) => {
        try {
            //Retrieving Session token using api endpoint which returns token based on the product role assigned to the user
            const credentials = await AuthCredentials.getSessionAuthCreds()
            let url = URL.parse(req.url);
            let opts = {};
            let pathname = url.query === null ? url.pathname : `${url.pathname}?${url.query}`;
            if (req.method === 'POST' || req.method === 'PUT') {
                opts = {
                    host: url.hostname,
                    method: req.method,
                    url: req.url,
                    data: req.body,
                    body: JSON.stringify(req.body),
                    path: pathname,
                    service: appConfig.global.SignAWS.service,
                    region: appConfig.global.Auth.region,
                    headers: {
                        'content-type': 'application/json',
                    }
                };
            } else {
                opts = {
                    host: url.hostname,
                    path: pathname,
                    url: req.url,
                    method: req.method,
                    service: appConfig.global.SignAWS.service,
                    region: appConfig.global.Auth.region,
                    headers: {
                        'Token': Auth.getToken()
                    }
                }
            }
            let signer = new aws4.RequestSigner(opts, {
                secretAccessKey: credentials.SecretKey,
                accessKeyId: credentials.AccessKeyId,
                sessionToken: credentials.SessionToken
            });
            let signedRequest = signer.sign();
            delete signedRequest.headers['Host'];
            delete signedRequest.headers['Content-Length'];
            return signedRequest;
        }
        catch (err) {
            throw err;
        }
    }

}
const httpService = new HttpService();

export default httpService;