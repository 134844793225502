export const SupplyPrivilegeTypes = {
    SET_NATIONAL_YIELD_PRIVILEGE: 'SET_NATIONAL_YIELD_PRIVILEGE',
    SET_CSV_DOWNLOAD_PRIVILEGE: 'SET_NATIONAL_YIELD_PRIVILEGE'
};

export const SupplyCSVDataTypes = {
    SET_CORN_CSV_DATA: 'SET_CORN_CSV_DATA',
    SET_SOY_CSV_DATA: 'SET_SOY_CSV_DATA'
};

export const CommonTypes = {
    SET_SELECTED_CROP: 'SET_SELECTED_CROP'
}