import { Component } from "react";
import appConfig from '../../_shared/appConfig/appConfig';
import Auth from '../modules/amplify/Auth'

var moment = require('moment');
class AuthCredentialService extends Component {

    getSessionAuthCreds = () => {
        return new Promise((resolve, reject) => {
            try {
                const credentials = JSON.parse(localStorage.getItem("cognito:credentials"));
                if (!this.validCredentials(credentials)) {
                    this.getAuthCredentials().then((result) => {
                        localStorage.setItem('cognito:credentials', JSON.stringify(result));
                        resolve(result);
                    })
                }
                else
                    resolve(credentials);
            }
            catch (err) {
                reject(err);
            }
        });
    }

    getAuthCredentials = async () => {
        await Auth.currentSession();
        const token = Auth.getToken();
        return new Promise((resolve, reject) => {
            try {
                const sessionTokenUrl = appConfig.urls.SESSION_TOKEN_URL;
                if (token) {
                    fetch(sessionTokenUrl, {
                        method: 'GET',
                        mode: 'cors',
                        cache: 'no-cache',
                        headers: { token: token },
                        referrer: 'client'
                    }).then((data) => data.json())
                        .then((resData) => {
                            if (resData.status) {
                                resolve(resData.data.Credentials);
                            }
                            else
                                window.location.replace(`${appConfig.urls.LOGIN_URL}/dashboard`);
                        });
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    validCredentials = (credentials) => {
        if (credentials === null || credentials === undefined) {
            return false;
        }

        if (credentials.AccessKeyId === undefined) {
            return false;
        }

        if (credentials.Expiration <= moment().utc().format()) {
            return false;
        }
        return true;
    }

}

const authCredentialService = new AuthCredentialService();

export default authCredentialService;