import {combineReducers} from 'redux';

import userReducer from './user/user.reducer';
import chartDescriptions from './chartDescriptions/chartDescriptions.reducer';
import demandReducer from './demand/demand.reducer';
import supplyReducer from './supply/supply.reducer';

export default combineReducers({
    user: userReducer,
    chartDescriptions: chartDescriptions,
    demand: demandReducer,
    supply: supplyReducer,
});