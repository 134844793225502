import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import loaderService from '../services/LoaderService';
import '../../assets/sass/modules/_loader.scss';

class GlobalLoader extends Component {

    constructor() {
        super();
        this.state = {
            hide: true
        }

    }

    componentDidMount = () => {

        this.subscription = loaderService.loaderState().subscribe(output => {
            if (output.show === false) {
                this.showLoader();
            }
            else {
                this.hideLoader();
            }
        });
    }

    componentWillUnmount = () => this.subscription.unsubscribe();

    showLoader = () => this.setState({ hide: false });

    hideLoader = () => this.setState({ hide: true });

    render() {
        const { hide } = this.state;
        const { show } = this.props;
        return (
            (hide === false || show === true) && <div className="globbal-loader bg-dark">
                <div className="loader-inner">
                    <svg version="1.1" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px" width="130px" height="130px" viewBox="0 0 80 80">
                        <path fill="#63b33f" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z" transform="rotate(318.206 25 25)">
                            <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform>
                        </path>
                    </svg>
                </div>
            </div>
        );
    }
}

export default withRouter(GlobalLoader);
