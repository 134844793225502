const demandActionTypes = {
    SET_WHEAT_EXPORT_PROJECTION_DATA: 'SET_WHEAT_EXPORT_PROJECTION_DATA',
    SET_WHEAT_COMMITMENTS_PROJECTION_DATA: 'SET_WHEAT_COMMITMENTS_PROJECTION_DATA',
    SET_SOY_EXPORT_PROJECTION_DATA: 'SET_SOY_EXPORT_PROJECTION_DATA',
    SET_SOY_COMMITMENTS_PROJECTION_DATA: 'SET_SOY_COMMITMENTS_PROJECTION_DATA',
    SET_SOYMEAL_EXPORT_PROJECTION_DATA: 'SET_SOYMEAL_EXPORT_PROJECTION_DATA',
    SET_SOYMEAL_COMMITMENTS_PROJECTION_DATA: 'SET_SOYMEAL_COMMITMENTS_PROJECTION_DATA',
    SET_SOYOIL_EXPORT_PROJECTION_DATA: 'SET_SOYOIL_EXPORT_PROJECTION_DATA',
    SET_SOYOIL_COMMITMENTS_PROJECTION_DATA: 'SET_SOYOIL_COMMITMENTS_PROJECTION_DATA',
    SET_CORN_EXPORT_PROJECTION_DATA: 'SET_CORN_EXPORT_PROJECTION_DATA',
    SET_CORN_COMMITMENTS_PROJECTION_DATA: 'SET_CORN_COMMITMENTS_PROJECTION_DATA',
    SET_CORN_ETHANOL_PRODUCTION_FIVE_YEAR_DATA: 'SET_CORN_ETHANOL_PRODUCTION_FIVE_YEAR_DATA',
    SET_CORN_ETHANOL_PROJECTION_DATA: 'SET_CORN_ETHANOL_PROJECTION_DATA',
    SET_CORN_ETHANOL_STOCKS_FIVE_YEAR_DATA: 'SET_CORN_ETHANOL_STOCKS_FIVE_YEAR_DATA',
    SET_CORN_ETHANOL_STOCKS_PRODUCTION_DATA: 'SET_CORN_ETHANOL_STOCKS_PRODUCTION_DATA'
}

export default demandActionTypes;