import sessionModule from '../SessionModule';
import { CognitoConstants } from '../../constants/cognito';
// import { sessionId } from '../utilities';

let dataMemory = {};
let sessionId = localStorage.getItem('sessionId');

/** @class */
export default class MemoryStorage {
    static syncPromise = null;
    /**
     * This is used to set a specific item in storage
     */
    static async setItem(key, value) {
        try {
            if(key.includes('idToken'))
            {
                localStorage.setItem(CognitoConstants.ID_TOKEN, value);
            }
            localStorage.setItem(key,value);
            await sessionModule.setSession(sessionId, key, value);// set session data from dynamoDB
        }
        catch (e) {
            console.log('setItem' + e.toString());
        }
        dataMemory[key] = value;
        return dataMemory[key];
    }

    /**
     * This is used to get a specific key from storage
     */
    static getItem(key) {
        try {
            return Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : undefined;
        }
        catch (e) {
            console.log("getItem" + e.toString());
        }
    }

    /**
     * This is used to remove an item from storage
     */
    static removeItem(key) {
        return delete dataMemory[key];
    }

    /**
     * This is used to clear the storage
     */
    static clear() {
        dataMemory = {};
        return dataMemory;
    }

    /**
     * Will sync the MemoryStorage data from AsyncStorage to storageWindow MemoryStorage
    */
    static sync() {
        if (!MemoryStorage.syncPromise) {
            MemoryStorage.syncPromise = new Promise((res, rej) => {
                // sync session data from dynamoDB
                sessionModule.getSession(sessionId).then((data) => {
                    dataMemory = data;
                    res();
                }).catch((err) => {
                    console.log(err);
                    rej(err)
                });
            });
        }

        return MemoryStorage.syncPromise;
    }
}