import MemoryStorage from '../modules/amplify/Storage';

const prefix = process.env.REACT_APP_ENV;

const globalConstants = {
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
        storage: MemoryStorage
    },
    AWS: {
        serviceProvider: 'CognitoIdentityServiceProvider'
    },
    SidebarConstants: {
        DEFAULT_OPEN_NAV: 'nationalSummaryNav'
    },
    SignAWS: {
        service: 'execute-api'
    },
    S3: {
        gsBucket: 'mainstreetanalytics',
        gsQABucket: `${prefix}analytics.mainstreetdata.co`
    },
    FilePath: {
        HOME: 'home',
        SUPPLY: 'supply',
        DEMAND: 'demand',
        CONFIG: 'config'
    },
    ImageYear: '2019'
}

export default globalConstants;