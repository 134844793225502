/* 
    QA - "qa-"
    Stage - "stage-"
    Production - ""
*/
// const prefix = 'qa-';
// const ssoAPIUrl = `https://${prefix}ssoapi.mainstreetdata.co`;
// const ssoLoginUrl = `http://${prefix}sso.mainstreetdata.co`;
// const pwAPIBaseUrl = `https://${prefix}analyticsapi.mainstreetdata.co`;
// const siteBaseUrl = `https://${prefix}analytics.mainstreetdata.co`
// const gsBucket = 'mainstreetanalytics';
// // const gsQABucket = `${prefix}analytics.mainstreetdata.co`
// const gsConfigPath = 'services/charts/config';
// const gsPath = 'services/charts';
// const gsBaseUrl = `https://storage.googleapis.com/`;
// const adminBaseUrl =`https://${prefix}adminportalapi.mainstreetdata.co`
// const analyticsUrl = `https://${prefix}analytics.mainstreetdata.co`

const ssoAPIUrl = process.env.REACT_APP_SSO_API_URL;
const ssoLoginUrl = process.env.REACT_APP_SSO_LOGIN_URL;
const pwAPIBaseUrl = process.env.REACT_APP_SERVICE_URL;
const siteBaseUrl = process.env.REACT_APP_BASE_URL;
const gsBucket = process.env.REACT_APP_GS_BUCKET;
const gsConfigPath = process.env.REACT_APP_CHART_CONFIG;
const gsPath = process.env.REACT_APP_GS_PATH;
const gsBaseUrl = process.env.REACT_APP_GS_BASE_URL;
const adminBaseUrl = process.env.REACT_APP_ADMIN_SERVICE_URL; 

const urlConstants = {
    BASE_URL: `${ssoAPIUrl}/session`,
    SESSION_URL: `${ssoAPIUrl}/session`,
    LOGIN_URL: `${ssoLoginUrl}`,
    IMAGE_URL: `${gsBaseUrl}/${gsPath}/`,
    IMAGE_ANALYTICS_PATH: `data/analytics/home/maps/`,
    SIGNED_URL: `${pwAPIBaseUrl}/url/generate`,
    SELECTED_STATE_URL: `${pwAPIBaseUrl}/forecast/image`,
    GCP_IMAGE_PATH: `${gsPath}/`,
    HOME_JSON_DATA: `${gsBaseUrl}${gsBucket}/${gsConfigPath}/homePage.json`,
    SUPPLY_JSON_DATA: `${gsBaseUrl}${gsBucket}/${gsConfigPath}/supplyPage.json`,
    DEMAND_JSON_DATA: `${gsBaseUrl}${gsBucket}/${gsConfigPath}/demandPage.json`,
    SESSION_TOKEN_URL: `${ssoAPIUrl}/sessiontoken?product=Analytics`,
    SITE_URL: `${siteBaseUrl}`,
    USER_ACCESS_CONTROL:`${adminBaseUrl}/users`,
    USER_SETTINGS: `${pwAPIBaseUrl}/users`,
    SELECTED_STATE_URL_CSV: `${pwAPIBaseUrl}/forecast/csv`,
    ANALYTICS_URL: `${siteBaseUrl}`
}

export default urlConstants;