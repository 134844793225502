class AccessControlHelper {

    static getAccessControl(accessControls, target) {
        if (accessControls !== null) {
            for (let control of accessControls) {
                if (control.privilege_type === target) {
                    return control;
                }
            }
        } else return {};
    }

    static getPrivilege(privileges, target) {
        if (privileges && privileges.length > 0) {
            for (let privilege of privileges) {
                if (privilege.name === target) {
                    return privilege;
                }
            }
        }
    }

    static hasPrivilege(privileges, target) {
        if (privileges && privileges.length > 0) {
            const privilege = privileges.find(element => element.name === target);
            if(privilege.is_accessible === 'true') {
                return true;
            }
            else {
                return false;
            }
        }
        return false;
    }

    static getRedirectRoute(accessControls) {
        let accessControl = this.getAccessControl(accessControls, 'page');
        if (this.hasPrivilege(accessControl.privileges, 'home')) {
            return '/home';
        } else if (this.hasPrivilege(accessControl.privileges, 'supply')) {
            return '/supply';
        } else if (this.hasPrivilege(accessControl.privileges, 'demand')) {
            return '/demand';
        }
    }

    static getHomeRoute(accessControls) {
        let accessControl = this.getAccessControl(accessControls, 'crop');
        if(this.hasPrivilege(accessControl.privileges, 'corn'))
            return '/home/yield-progress-corn';
        else
            return '/home/yield-progress-soybeans';
    }

    static getAllRoutesByTab(routes, tab, page) {
        let output = [];
        if (routes && routes.length > 0) {
            for (let route of routes) {
                if (route.Tab === tab && route.Page === page && route.IsAllowed === 1) {
                    output.push(route);
                }
            }
        }
        return output;
    }

    static getAllRoutesForPage(routes, page) {
        let output = {};
        if (routes && routes.length > 0) {
            for (let route of routes) {
                if (output[route.Tab] === undefined) {
                    output[route.Tab] = [];
                }

                if (route.Page === page && route.IsAllowed === 1) {
                    output[route.Tab].push(route);
                }
            }
        }
        return output;
    }

    static getAllRoutesByPage(routes) {
        let output = {};
        if (routes && routes.length > 0) {
            for (let route of routes) {
                if (output[route.Page] === undefined) {
                    output[route.Page] = [];
                }

                if (route.IsAllowed === 1) {
                    output[route.Page].push(route);
                }
            }
        }
        return output;
    }

    static getDefaultRoutesByPage(defaultRoutes) {
        let output = {};
        if (defaultRoutes && defaultRoutes.length > 0) {
            for (let defaultRoute of defaultRoutes) {
                output[defaultRoute.Page] = defaultRoute.Route;
            }
        }
        return output;
    }
}

export default AccessControlHelper;