const CognitoConstants = {
    RESPONSE_TYPE: 'id_token token',
    USER_ID: 'custom:userId',
    PROFILE: 'cognito.profile',
    GROUPS: 'cognito:groups',
    IS_MRV: 'custom:isMrvUser',
    FIRST_NAME: 'given_name',
    LAST_NAME: 'family_name',
    ID_TOKEN: 'cognito:idToken',
    ACCESS_TOKEN: 'cognito:accessToken',
    MARKET_STATE_PERSIST: 'MarketState',
}

export { CognitoConstants };