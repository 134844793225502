const languageConstants = {
    errors: {
        awsAuthErrors: {
            undefinedError: 'Undefiend error: No User Found.'
        },
        globalErrors: {
            noData: 'No data found.'
        }
    }
}

export default languageConstants;