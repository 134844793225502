import Auth from '@aws-amplify/auth';
import sessionModule from '../SessionModule';
import Storage from './Storage';
import { CognitoConstants } from '../../constants/cognito';
import AuthCredentialService from '../../services/AuthCredentialService';
import JwtTokenHelper from '../../helpers/JwtTokenHelper';
import appConfig from '../../appConfig/appConfig';
Auth.configure(appConfig.global.Auth);

class AuthService {

    constructor() {
        this.jwtTokenHelper = new JwtTokenHelper();
    }

    async signIn(username, password) {
        try {
            const user = await Auth.signIn(username, password);
            return user;
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                console.log('signIn Error:- User not Confirmed');
            } else if (err.code === 'PasswordResetRequiredException') {
                console.log('signIn Error:- Password Reset Required');
            } else if (err.code === 'NotAuthorizedException') {
                console.log('signIn Error:- User not Authorized');
            } else if (err.code === 'UserNotFoundException') {
                console.log('signIn Error:- User not Found');
            } else {
                console.log('signIn Error:- ' + err);
            }
        }
    }

    async signOut() {
        try {
            const sessionId = localStorage.getItem('sessionId');
            // await SessionModule.deleteSession(sessionId);// delete session data from dynamoDB
            localStorage.clear();
            localStorage.setItem('sessionId', sessionId);
            //loaderService.loader(false);
        } catch (err) {
            //loaderService.loader(false);
            throw err;
        }
    }

    async isLoggedIn() {
        try {
            let loginstate = await Auth.currentAuthenticatedUser();
            // console.log("Login state : ", loginstate);
            if (loginstate) {
                await this.refresh();
                return true;
            }
            else {
                return false;
            }
        } catch (err) {
            // console.log("false", err);
            return false;
        }
    }

    isAuthorised() {
        try {
            const token = localStorage.getItem(CognitoConstants.ID_TOKEN);
            if (token === '') {
                return false;
            }

            const decoded = this.jwtTokenHelper.decodeToken(token);

            const groups = decoded['cognito:groups'];

            for (let group of groups) {
                if (group && group.includes("PWY")) {
                    return true;
                }
            }

            return false;
        }
        catch (err) {
            return false;
        }
    }

    isCropView() {
        try {
            const token = localStorage.getItem(CognitoConstants.ID_TOKEN);
            if (token === '') {
                return false;
            }

            const decoded = this.jwtTokenHelper.decodeToken(token);

            const groups = decoded['cognito:groups'];

            if (groups.includes('PWYCropView')) {
                return true;
            } else {
                return false;
            }
        }
        catch (err) {
            return false;
        }
    }

    async isValidSessionToken()
    {
        try{
            await AuthCredentialService.getSessionAuthCreds();
            return true;
        }
        catch (ex){
            return false;
        }
    }

    isTokenExpired(token) {
        try {
            if (token === '') {
                return false;
            }

            const decoded = this.jwtTokenHelper.decodeToken(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else {
                return false;
            }
        }
        catch (err) {
            return false;
        }
    }

    getToken() {
        let token = localStorage.getItem(CognitoConstants.ID_TOKEN);
        return token;
    }

    // To get Guid to fetch access control 
    getUserGuid() {
        let jwtToken = localStorage.getItem("cognito:idToken");
        let sections = (jwtToken).split('.');
        let payload = JSON.parse(Buffer.from(sections[1], 'base64').toString());
        let guid = payload.sub
        return guid
    }

    async getIdToken() {
        let token = '';
        try {
            let session = await Auth.currentSession();
            if (session.isValid()) {
                token = session.getIdToken().jwtToken;
            }
        }
        catch (ex) {
            console.log(ex.toString());
        }
        return token;
    }

    async getCurrentUserInfo() {
        try {
            return await Auth.currentUserInfo();
        }
        catch (ex) {
            return ex.toString();
        }
    }

    async getCurrentUserPoolUser() {
        try {
            return await Auth.currentUserPoolUser();
        }
        catch (ex) {
            return ex.toString();
        }
    }

    async getCurrentAuthenticatedUser() {
        try {
            return await Auth.currentAuthenticatedUser();
        }
        catch (ex) {
            return ex.toString();
        }
    }

    async refresh() {
        let isValid = false;
        try {
            let session = await Auth.currentSession();
            isValid = true;
            await Storage.setItem(CognitoConstants.ID_TOKEN, session.getIdToken().jwtToken);
            await Storage.setItem(CognitoConstants.ACCESS_TOKEN, session.getAccessToken().jwtToken);
        }
        catch (ex) {
            console.log("refresh()" + ex.toString());
        }

        return isValid;
    }

    async currentSession() {
        try {
            return await Auth.currentSession();
        } catch (ex) {
            return ex.toString();
        }
    }

    async currentCredentials() {
        try {
            return await Auth.currentCredentials();
        }
        catch (ex) {
            return ex.toString();
        }
    }
}

export default new AuthService();