import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import loaderService from '../../_shared/services/LoaderService';

import auth from '../../_shared/modules/amplify/Auth';
import appConfig from '../../_shared/appConfig/appConfig';

class LoginPage extends Component {
    constructor() {
        super();
        this.showLoader(true);
    }

    componentWillMount = () => {
        // this.showLoader(true);
    }

    componentDidMount = async () => {
        document.title = "Login - Main Street Data";
        try {
            const resp = await auth.refresh();
            if (resp) {
                await setTimeout(() => { }, 2000);
                window.location.replace('/home');
            } else {
                window.location.replace(appConfig.urls.LOGIN_URL);
                console.log('error');
            }
        } catch (err) {
            console.log(err);
        }
    }

    showLoader = (status) => { loaderService.loader(status); }

    render = () => {
        return (
            <div>

            </div>
        );
    }
}

export default withRouter(LoginPage);