import { userActionTypes } from "./user.types";

export const setLoginState = loginState => ({
    type: userActionTypes.SET_LOGIN_STATE,
    payload: loginState
});

export const setIsAuthorised = authState => ({
    type: userActionTypes.SET_AUTH_STATE,
    payload: authState
});

export const setSessionState = sessionState => ({
    type: userActionTypes.SET_SESSION_STATE,
    payload: sessionState
});

export const setUserAccessControlState = accessControls => ({
    type: userActionTypes.SET_USER_ACCESS_CONTROLS_STATE,
    payload: accessControls
});

export const setIsFirstLogin = firstTimeUser => ({
    type: userActionTypes.SET_IS_FIRST_LOGIN,
    payload: firstTimeUser
});

export const setDefaultRoutes = defaultRoutes => ({
    type: userActionTypes.SET_DEFAULT_ROUTES,
    payload: defaultRoutes
});


export const setFilePaths = filePaths => ({
    type: userActionTypes.SET_FILE_PATHS,
    payload: filePaths
});