import { SupplyPrivilegeTypes, SupplyCSVDataTypes, CommonTypes } from './supply.types';

const INITIAL_STATE = {
    nationalSummary: false,
    csvDownloads: false,
    cornCsvData: [],
    soyCsvData: [],
    selectedCrop: '',
    cornCrop: 'corn',
    soyCrop: 'soy'
};

const supplyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SupplyPrivilegeTypes.SET_NATIONAL_YIELD_PRIVILEGE:
            return {
                ...state,
                nationalSummary: action.payload
            };
        case SupplyPrivilegeTypes.SET_CSV_DOWNLOAD_PRIVILEGE:
            return {
                ...state,
                csvDownloads: action.payload
            };
        case SupplyCSVDataTypes.SET_CORN_CSV_DATA:
            return {
                ...state,
                cornCsvData: action.payload
            };
        case SupplyCSVDataTypes.SET_SOY_CSV_DATA:
            return {
                ...state,
                soyCsvData: action.payload
            };
        case CommonTypes.SET_SELECTED_CROP:
            return {
                ...state,
                selectedCrop: action.payload
            };
        default:
            return state;
    }
}

export default supplyReducer;