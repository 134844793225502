import appConfig from "../appConfig/appConfig";

class SessionModule {

    static async getSession (sessionId) {
        try {
            const result = await fetch(`${appConfig.urls.SESSION_URL}?browserId=${sessionId}`);
            const responseJSON = await result.json();
            if(responseJSON.status) {
                return responseJSON.data;
            } else {
                return responseJSON.error;
            }
        } catch (err) {
            return appConfig.language.errors.awsAuthErrors.undefinedError;
        }
    }

    static async setSession (sessionId, sessionKey, sessionValue) {
        try {
            const content = {
                "BrowserId": sessionId,
                "SessionKey": sessionKey,
                "SessionValue": sessionValue
            };
    
            const result = await fetch(
                appConfig.urls.SESSION_URL, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(content)
                }
            );
            let responseJSON = result.json();
            if(responseJSON.status) {
                localStorage.setItem('sessionId', sessionId);
                return ({ success: true });
            } else {
                return responseJSON.error;
            }
        } catch (err) {
            console.log('err', err);
            return appConfig.language.errors.awsAuthErrors.undefinedError;
        }        
    }

    static async deleteSession (sessionId) {
        try {
            const result = await fetch(`${appConfig.urls.SESSION_URL}?browserId=${sessionId}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const responseJSON = result.json();
            if(responseJSON.status) {
                return ({ success: true });
            } else {
                return responseJSON.error;
            }
        } catch (err) {
            return appConfig.language.errors.awsAuthErrors.undefinedError;
        }
    }
}

export default SessionModule;