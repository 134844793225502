import { Subject } from 'rxjs';

const loaderState = new Subject();

const loaderService = {
    loader: status => loaderState.next({ show: status }),
    loaderState: () => loaderState.asObservable()
}

export default loaderService;
