import demandActionTypes from './demand.types';

const INITIAL_STATE = {
    soy: {
        exportsProjectionData: null,
        commitmentsProjectionData: null
    },
    wheat: {
        exportsProjectionData: null,
        commitmentsProjectionData: null
    },
    soyMeal: {
        exportsProjectionData: null,
        commitmentsProjectionData: null
    },
    soyOil: {
        exportsProjectionData: null,
        commitmentsProjectionData: null
    },
    corn: {
        exportsProjectionData: null,
        commitmentsProjectionData: null,
        ethanolProductionFiveYearData: null,
        ethanolProjectionData: null,
        ethanolStocksFiveYearsData: null,
        ethanolStocksProductionData : null
    }
}

const demandReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case demandActionTypes.SET_WHEAT_EXPORT_PROJECTION_DATA:
            return {
                ...state,
                wheat: {
                    ...state.wheat,
                    exportsProjectionData: action.payload
                }
            };
        case demandActionTypes.SET_WHEAT_COMMITMENTS_PROJECTION_DATA:
            return {
                ...state,
                wheat: {
                    ...state.wheat,
                    commitmentsProjectionData: action.payload
                }
            };
        case demandActionTypes.SET_SOY_EXPORT_PROJECTION_DATA:
            return {
                ...state,
                soy: {
                    ...state.soy,
                    exportsProjectionData: action.payload
                }
            };
        case demandActionTypes.SET_SOY_COMMITMENTS_PROJECTION_DATA:
            return {
                ...state,
                soy: {
                    ...state.soy,
                    commitmentsProjectionData: action.payload
                }
            };
        case demandActionTypes.SET_SOYMEAL_EXPORT_PROJECTION_DATA:
            return {
                ...state,
                soyMeal: {
                    ...state.soyMeal,
                    exportsProjectionData: action.payload
                }
            };
        case demandActionTypes.SET_SOYMEAL_COMMITMENTS_PROJECTION_DATA:
            return {
                ...state,
                soyMeal: {
                    ...state.soyMeal,
                    commitmentsProjectionData: action.payload
                }
            };
        case demandActionTypes.SET_SOYOIL_EXPORT_PROJECTION_DATA:
            return {
                ...state,
                soyOil: {
                    ...state.soyOil,
                    exportsProjectionData: action.payload
                }
            };
        case demandActionTypes.SET_SOYOIL_COMMITMENTS_PROJECTION_DATA:
            return {
                ...state,
                soyOil: {
                    ...state.soyOil,
                    commitmentsProjectionData: action.payload
                }
            };
        case demandActionTypes.SET_CORN_EXPORT_PROJECTION_DATA:
            return {
                ...state,
                corn: {
                    ...state.corn,
                    exportsProjectionData: action.payload
                }
            };
        case demandActionTypes.SET_CORN_COMMITMENTS_PROJECTION_DATA:
            return {
                ...state,
                corn: {
                    ...state.corn,
                    commitmentsProjectionData: action.payload
                }
            };
        case demandActionTypes.SET_CORN_ETHANOL_PRODUCTION_FIVE_YEAR_DATA:
            return {
                ...state,
                corn: {
                    ...state.corn,
                    ethanolProductionFiveYearData: action.payload
                }
            };
        case demandActionTypes.SET_CORN_ETHANOL_PROJECTION_DATA:
            return {
                ...state,
                corn: {
                    ...state.corn,
                    ethanolProjectionData: action.payload
                }
            };
        case demandActionTypes.SET_CORN_ETHANOL_STOCKS_FIVE_YEAR_DATA:
            return {
                ...state,
                corn: {
                    ...state.corn,
                    ethanolStocksFiveYearsData: action.payload
                }
            };
        case demandActionTypes.SET_CORN_ETHANOL_STOCKS_PRODUCTION_DATA:
            return {
                ...state,
                corn: {
                    ...state.corn,
                    ethanolStocksProductionData: action.payload
                }
            }
        default:
            return state;
    }
}

export default demandReducer;