import React, { Component } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { setUserAccessControlState, setDefaultRoutes, setFilePaths } from '../../_shared/redux/user/user.actions';
import { setSelectedCrop } from '../../_shared/redux/supply/supply.actions';

import UserAccessControl from '../services/UserAccessControl';
import AccessControlHelper from '../helpers/AccessControlHelper';

/*
This file is used to block the login route from accessing.
i.e. it will always goto the dashboard page if the user is logged in and in times when the user is not logged in then the user will see the login page.
*/

class AuthGuard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectRoute: ''
        }
    }

    componentDidMount = async () => {
        const {
            isAuthenticated,
            setUserAccessControlState,
            setDefaultRoutes,
            setFilePaths,
            setSelectedCrop,
            cornCrop, 
            soyCrop
        } = this.props;
        if (isAuthenticated) {
            let userAccessControls = await UserAccessControl.getUserAccessControl();

            if (userAccessControls && userAccessControls.data) {
                let accessControl = AccessControlHelper.getAccessControl( userAccessControls.data, "crop" );
                let cornAccess = AccessControlHelper.hasPrivilege( accessControl.privileges, "corn" );
        
                if (cornAccess) {
                    setSelectedCrop(cornCrop);
                } else {
                    setSelectedCrop(soyCrop);
                }
            }

            setUserAccessControlState(userAccessControls.data);

            let userSettings = await UserAccessControl.getUserSettings();
            setDefaultRoutes(userSettings.data[0] && userSettings.data[0].DefaultRoutes);
            setFilePaths(userSettings.data[0] && userSettings.data[0].FilePaths);

            let redirectRoute = AccessControlHelper.getRedirectRoute(userAccessControls.data);
            this.setState({
                redirectRoute: redirectRoute
            });

        } else {
            return (<Redirect to='/login' />);
        }
    };

    render() {
        const { isAuthenticated } = this.props;
        const { redirectRoute } = this.state;
        return (
            <Route render={(props) => !isAuthenticated ? (<Component {...props} />) : (<Redirect to={redirectRoute} />)} />
        );
    }
}

const mapStateToProps = ({ user: { accessControls }, supply: {cornCrop, soyCrop} }) => ({
    accessControls,
    cornCrop,
    soyCrop
});

const mapDispatchToProps = dispatch => ({
    setUserAccessControlState: data => (dispatch(setUserAccessControlState(data))),
    setDefaultRoutes: data => (dispatch(setDefaultRoutes(data))),
    setFilePaths: data => (dispatch(setFilePaths(data))),
    setSelectedCrop: data => (dispatch(setSelectedCrop(data)))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthGuard));
