import httpService from './HttpService';
import appConfig from '../appConfig/appConfig';
import Auth from '../modules/amplify/Auth';


class UserAccessControl {

    // To Get UserAccessControl 
    getUserAccessControl = async () => {
        try {
            const Token = Auth.getToken();
            const guid = Auth.getUserGuid();
            return await httpService.get(`${appConfig.urls.USER_ACCESS_CONTROL}/${guid}/product/analytics/privileges?type=array`, {
                Token
            });
        } catch (err) {
            console.log('Error', err);
        }
    }

    updateUserSettings = async (body) => {
        try {
            const Token = Auth.getToken();
            const guid = Auth.getUserGuid();
            const headers = {
                "token": Token
            }
            return await httpService.post(`${appConfig.urls.USER_SETTINGS}/${guid}/settings`, body, headers);
        } catch (err) {
            console.log('Error', err);
        }
    }

    getUserSettings = async () => {
        try {
            const guid = Auth.getUserGuid();
            return await httpService.get(`${appConfig.urls.USER_SETTINGS}/${guid}/settings`);
        } catch (err) {
            console.log('Error', err);
        }
    }
}

export default new UserAccessControl();