import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// import './assets/css/index.css';
import 'mdbreact/dist/css/mdb.css';
import './assets/sass/styles.scss';

import { setLoginState, setIsAuthorised } from './_shared/redux/user/user.actions'

import GlobalLoader from './_shared/modules/GlobalLoader';
import AuthService from './_shared/modules/amplify/Auth';
import appConfig from './_shared/appConfig/appConfig'
import AuthGuard from './_shared/guards/AuthGuard';
import LoginPage from './pages/auth/LoginPage';
import AccessGuard from './_shared/guards/AccessGuard';

//Import the layouts and add lazy loading.
const AuthLayout = lazy(() => import('./_shared/_layout/AuthLayout'));
const BlankLayout = lazy(() => import('./_shared/_layout/BlankLayout'));
const MainLayout = lazy(() => import('./_shared/_layout/MainLayout'));

class App extends Component {

  componentDidMount = async () => {
    const { setLoginState, setIsAuthorised } = this.props;
    let isUserLogin = await AuthService.isLoggedIn();
    setLoginState(isUserLogin);
    let isAuthorised = await AuthService.isAuthorised();
    setIsAuthorised(isAuthorised);
  }

  render() {
    const { isUserLogin, isAuthorised } = this.props;

    if (typeof(isUserLogin) === 'object') {
      return (<GlobalLoader show={true}></GlobalLoader>);
    }

    if (typeof isUserLogin === 'boolean' && isUserLogin === true) {
      return (
        <Suspense fallback={<GlobalLoader show={true}></GlobalLoader>}>
          <Switch>
            <AuthGuard isAuthenticated={isUserLogin} exact path="/" component={LoginPage} />
            <Route path="/auth" render={() => <AuthLayout isAuthenticated={isAuthorised} />} />
            <AccessGuard path='/home' isAuthorised={isAuthorised} homeSidebar={true} component={MainLayout} />
            <AccessGuard path='/demand' isAuthorised={isAuthorised} demandSidebar={true} component={MainLayout} />
            <AccessGuard path='/supply' isAuthorised={isAuthorised} demandSidebar={false} component={MainLayout} />
            <Route path='/page-not-found' component={BlankLayout} />
            <Route path='/login' component={() => { window.location.href = `${appConfig.urls.LOGIN_URL}/dashboard`; return null; }} />
            <Route path="" render={() => (<Redirect to="/page-not-found" />)} />
          </Switch>
          {/* <Loader>{console.log("Loader Loader Loader")}</Loader> */}
        </Suspense>
      );
    } else {
      let SSOURL = appConfig.urls.LOGIN_URL;
      let baseURL = appConfig.urls.SITE_URL;
      let redirectURL = SSOURL + '/' + encodeURI('?redirectUrl=' + baseURL);
      window.location.href = redirectURL;
      return '';
    }
  }
}

const mapStateToProps = ({ user: {isUserLogin, isAuthorised} }) => ({
  isUserLogin,
  isAuthorised
});

const mapDispatchToProps = dispatch => ({
  setLoginState: loginState => dispatch(setLoginState(loginState)),
  setIsAuthorised: isAuthorised => dispatch(setIsAuthorised(isAuthorised)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
