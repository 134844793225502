import { SupplyPrivilegeTypes, SupplyCSVDataTypes, CommonTypes } from './supply.types';

export const setNationalSummaryPrivilege = data => ({
    type: SupplyPrivilegeTypes.SET_NATIONAL_YIELD_PRIVILEGE,
    payload: data
});

export const setCSVDownloadPrivilege = data => ({
    type: SupplyPrivilegeTypes.SET_CSV_DOWNLOAD_PRIVILEGE,
    payload: data
});

export const setCornCSVData = data => ({
    type: SupplyCSVDataTypes.SET_CORN_CSV_DATA,
    payload: data
});

export const setSoyCSVData = data => ({
    type: SupplyCSVDataTypes.SET_SOY_CSV_DATA,
    payload: data
});

export const setSelectedCrop = data => ({
    type: CommonTypes.SET_SELECTED_CROP,
    payload: data
});