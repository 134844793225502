import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";

const AccessGuard = ({ component: Component, isAuthorised, ...rest }) => (
  <Route {...rest} render={(props) => (
     isAuthorised === true ? 
        <Component {...rest} /> : <Redirect to={{ pathname: '/', state: { from: props.location }}} />   
  )} />
);

export default AccessGuard;
