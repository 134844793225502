import { userActionTypes } from './user.types';

const INITIAL_STATE = {
    isUserLogin: {},
    isAuthorised: false,
    isValidSession: false,
    isFirstLogin: null,
    accessControls: null,
    defaultRoutes: [],
    filePaths: []
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case userActionTypes.SET_LOGIN_STATE:
            return {
                ...state,
                isUserLogin: action.payload
            };
        case userActionTypes.SET_AUTH_STATE:
            return {
                ...state,
                isAuthorised: action.payload
            };
        case userActionTypes.SET_SESSION_STATE:
            return {
                ...state,
                isValidSession: action.payload
            }
        case userActionTypes.SET_USER_ACCESS_CONTROLS_STATE:
            return {
                ...state,
                accessControls: action.payload
            }
        case userActionTypes.SET_IS_FIRST_LOGIN:
            return {
                ...state,
                isFirstLogin: action.payload
            }
        case userActionTypes.SET_DEFAULT_ROUTES:
            return {
                ...state,
                defaultRoutes: action.payload
            }
        case userActionTypes.SET_FILE_PATHS:
            return {
                ...state,
                filePaths: action.payload
            }
        default:
            return state;
    }
}

export default userReducer;